/*const cyrb53 = function(str, seed = 0) {
	let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
	h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
	return 4294967296 * (2097151 & h2) + (h1>>>0);
};*/

var alpha2Toalpha3 = {
	AF: 'AFG', AX: 'ALA', AL: 'ALB', DZ: 'DZA', AS: 'ASM', AD: 'AND', AO: 'AGO', AI: 'AIA', AQ: 'ATA', AG: 'ATG', AR: 'ARG', AM: 'ARM', AW: 'ABW', AU: 'AUS', AT: 'AUT', AZ: 'AZE', BS: 'BHS', BH: 'BHR', BD: 'BGD', BB: 'BRB',
	BY: 'BLR', BE: 'BEL', BZ: 'BLZ', BJ: 'BEN', BM: 'BMU', BT: 'BTN', BO: 'BOL', BA: 'BIH', BW: 'BWA', BV: 'BVT', BR: 'BRA', VG: 'VGB', IO: 'IOT', BN: 'BRN', BG: 'BGR', BF: 'BFA', BI: 'BDI', KH: 'KHM', CM: 'CMR', CA: 'CAN', CV: 'CPV',
	KY: 'CYM', CF: 'CAF', TD: 'TCD', CL: 'CHL', CN: 'CHN', HK: 'HKG', MO: 'MAC', CX: 'CXR', CC: 'CCK', CO: 'COL', KM: 'COM', CG: 'COG', CD: 'COD', CK: 'COK', CR: 'CRI', CI: 'CIV', HR: 'HRV', CU: 'CUB', CY: 'CYP', CZ: 'CZE', DK: 'DNK',
	DJ: 'DJI', DM: 'DMA', DO: 'DOM', EC: 'ECU', EG: 'EGY', SV: 'SLV', GQ: 'GNQ', ER: 'ERI', EE: 'EST', ET: 'ETH', FK: 'FLK', FO: 'FRO', FJ: 'FJI', FI: 'FIN', FR: 'FRA', GF: 'GUF', PF: 'PYF', TF: 'ATF', GA: 'GAB', GM: 'GMB', GE: 'GEO',
	DE: 'DEU', GH: 'GHA', GI: 'GIB', GR: 'GRC', GL: 'GRL', GD: 'GRD', GP: 'GLP', GU: 'GUM', GT: 'GTM', GG: 'GGY', GN: 'GIN', GW: 'GNB', GY: 'GUY', HT: 'HTI', HM: 'HMD', VA: 'VAT', HN: 'HND', HU: 'HUN', IS: 'ISL', IN: 'IND', ID: 'IDN',
	IR: 'IRN', IQ: 'IRQ', IE: 'IRL', IM: 'IMN', IL: 'ISR', IT: 'ITA', JM: 'JAM', JP: 'JPN', JE: 'JEY', JO: 'JOR', KZ: 'KAZ', KE: 'KEN', KI: 'KIR', KP: 'PRK', KR: 'KOR', KW: 'KWT', KG: 'KGZ', LA: 'LAO', LV: 'LVA', LB: 'LBN', LS: 'LSO',
	LR: 'LBR', LY: 'LBY', LI: 'LIE', LT: 'LTU', LU: 'LUX', MK: 'MKD', MG: 'MDG', MW: 'MWI', MY: 'MYS', MV: 'MDV', ML: 'MLI', MT: 'MLT', MH: 'MHL', MQ: 'MTQ', MR: 'MRT', MU: 'MUS', YT: 'MYT', MX: 'MEX', FM: 'FSM', MD: 'MDA', MC: 'MCO',
	MN: 'MNG', ME: 'MNE', MS: 'MSR', MA: 'MAR', MZ: 'MOZ', MM: 'MMR', NA: 'NAM', NR: 'NRU', NP: 'NPL', NL: 'NLD', AN: 'ANT', NC: 'NCL', NZ: 'NZL', NI: 'NIC', NE: 'NER', NG: 'NGA', NU: 'NIU', NF: 'NFK', MP: 'MNP', NO: 'NOR', OM: 'OMN',
	PK: 'PAK', PW: 'PLW', PS: 'PSE', PA: 'PAN', PG: 'PNG', PY: 'PRY', PE: 'PER', PH: 'PHL', PN: 'PCN', PL: 'POL', PT: 'PRT', PR: 'PRI', QA: 'QAT', RE: 'REU', RO: 'ROU', RU: 'RUS', RW: 'RWA', BL: 'BLM', SH: 'SHN', KN: 'KNA', LC: 'LCA',
	MF: 'MAF', PM: 'SPM', VC: 'VCT', WS: 'WSM', SM: 'SMR', ST: 'STP', SA: 'SAU', SN: 'SEN', RS: 'SRB', SC: 'SYC', SL: 'SLE', SG: 'SGP', SK: 'SVK', SI: 'SVN', SB: 'SLB', SO: 'SOM', ZA: 'ZAF', GS: 'SGS', SS: 'SSD', ES: 'ESP', LK: 'LKA',
	SD: 'SDN', SR: 'SUR', SJ: 'SJM', SZ: 'SWZ', SE: 'SWE', CH: 'CHE', SY: 'SYR', TW: 'TWN', TJ: 'TJK', TZ: 'TZA', TH: 'THA', TL: 'TLS', TG: 'TGO', TK: 'TKL', TO: 'TON', TT: 'TTO', TN: 'TUN', TR: 'TUR', TM: 'TKM', TC: 'TCA', TV: 'TUV',
	UG: 'UGA', UA: 'UKR', AE: 'ARE', GB: 'GBR', US: 'USA', UM: 'UMI', UY: 'URY', UZ: 'UZB', VU: 'VUT', VE: 'VEN', VN: 'VNM', VI: 'VIR', WF: 'WLF', EH: 'ESH', YE: 'YEM', ZM: 'ZMB', ZW: 'ZWE', XK: 'XKX', XX: 'XXX'
};
var alpha3Toalpha2 = {}
try{
	for( let [k,v] of Object.entries(alpha2Toalpha3) )
		alpha3Toalpha2[v]=k
}catch(e){}

(function(proxied_open){
	var top_domain = location.hostname.split(".").slice(-2).join(".");
	CONF["cached_keys"] = {}
	//var fetch_index  = 0;
	//var fetch_result = {};

	window.XMLHttpRequest.prototype.open = function(){
		if( arguments[1].indexOf("/key/silk/mediaid/") > -1 || arguments[1].indexOf("/silk/events/") > -1 || arguments[1].indexOf("/key-service/mlbnKid") > -1 || arguments[1].indexOf('playback.svcs.plus.espn.com/events/') > -1 ){
			let oldUrl = arguments[1]

			arguments[1] = arguments[1].replace( "https://mf.svc.nhl.com/", `https://api.${top_domain}/api/get_cypher/espn/` );
			arguments[1] = arguments[1].replace( "https://playback.svcs.plus.espn.com/", `https://api.${top_domain}/api/get_cypher/espn/` );
			arguments[1] = arguments[1].replace( "https://playback.svcs.mlb.com/", `https://api.${top_domain}/api/get_cypher/mlb/` );

			this.onload = function(event){
				var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(event.target.response)));
				CONF["cached_keys"][oldUrl] = 'data:;base64,' + base64String;
			}
		}


		/*if( arguments[1].indexOf(`https://spottnhl.akamaized.net/`) == 0 ){
			if( CONF['sport'] == "NHL" )
				var PROXY = fetchRobot.connect({ url: 'https://availablestreams.com/fetch.html' });

			fetch_index += 1;
			PROXY.fetch(arguments[1]).then(response => response.text()).then( m3u8 => {fetch_result[fetch_index] = m3u8} );
			arguments[1] = "/assets/empty#" + fetch_index
		}

			in getter:
			console.log( this, arguments )
			if( this['responseURL'].indexOf("/assets/empty") > -1 ){
				var idx = this['__zone_symbol__xhrURL'];

			}

			<script src="https://rawgit.com/krakenjs/fetch-robot/master/dist/fetch-robot.min.js" async></script>
		*/

		/*if( arguments[1].indexOf(`https://archive.${top_domain}/`) == 0 ){
			proxied_open.apply(this, [].slice.call(arguments))
			this.setRequestHeader("Authentication", "Basic " + btoa( PLAYER.core_service.premiumDetails.code + ":") )
			return
		}*/

		return proxied_open.apply(this, [].slice.call(arguments));
	};
})(window.XMLHttpRequest.prototype.open);

(function(http){
	var desc = Object.getOwnPropertyDescriptor(
		http.prototype, 'responseText'
	);

	Object.defineProperty(
		http.prototype, "responseText",
		{
			get: function(){
				let text = desc.get.apply( this, arguments )
				if( text.startsWith('#EXTM3U') ){
					for( let url in CONF["cached_keys"] )
						text = text.replace( new RegExp(url, 'g'), CONF["cached_keys"][url] )
				}
				return text;
			},

			set: function(V){
				desc.set.apply( this, arguments );
			},

			enumerable: desc.enumerable,
			configurable: desc.configurable
		}
	);

})(self.XMLHttpRequest);
